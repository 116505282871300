/* General styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
    color: black;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .logo {
    width: 300px;
    height: auto;
    margin-bottom: 0rem;
  }
  
  .hold-message {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0rem;
    color: black;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0rem;
    color: red;
  }
  
  .details {
    color: black;
    text-transform: uppercase;
  }
  
  .countdown {
    display: flex;
    justify-content: space-between;
    width: 240px;
    margin-bottom: 2rem;
  }
  
  .countdown-item {
    text-align: center;
  }
  
  .countdown-value {
    font-size: 2rem;
    font-weight: bold;
    color: red;
  }
  
  .countdown-label {
    font-size: 1rem;
  }
  
  .current-time {
    font-size: 1rem;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .hold-message {
      font-size: 1rem;
    }
  
    .countdown-value {
      font-size: 1.5rem;
    }
  
    .countdown-label {
      font-size: 0.8rem;
    }
  
    .current-time {
      font-size: 0.8rem;
    }
  }
  